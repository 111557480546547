import axios from "axios";
import store from "@/store";
import { POSITION, useToast } from "vue-toastification";
import router from "@/router";

interface Subscriber {
  (token: string): void;
}

const baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : ``;
const axiosClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
let subscribers: Subscriber[] = [];
let isRefreshing = false;
const toast = useToast();

function onRefreshed(accessToken: string): void {
  subscribers.map((cb) => cb(accessToken));
}

function subscribeTokenRefresh(cb: Subscriber): void {
  subscribers.push(cb);
}

axiosClient.interceptors.request.use(
  (request) => {
    if (request.headers && store.state.user.accessToken) {
      request.headers.Authorization = `Bearer ${store.state.user.accessToken}`;
    }
    if (request.headers && request.url?.includes("docusign")) {
      const { userData } = store.state.docusign;
      request.headers["X-Docusign-AccountId"] = userData.profile?.accountId;
      request.headers["X-Docusign-BaseUrl"] = userData.profile?.baseUrl;
      request.headers["X-Docusign-Token"] = userData?.accessToken;
    }
    if (request.headers && request.url?.includes("rei")) {
      const { userData } = store.state.rei;
      request.headers["x-rei-token"] = userData.accessToken;
      request.headers["x-rei-state"] = userData.state;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const refreshToken: string | null = store.state.user.refreshToken;
    if (error.message === "Network Error") {
      toast.clear();
      toast.error("Some problem with internet connection", {
        position: POSITION.TOP_LEFT,
      });
      return;
    }
    if (error.response?.status === 401) {
      const currentRout = router.currentRoute.value;
      if (refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          axios
            .get(`${baseURL}/auth/refreshToken`, {
              params: { refreshToken },
            })
            .then((response) => {
              const { accessToken, refreshToken } = response.data;
              store.commit("user/setTokens", { accessToken, refreshToken });
              isRefreshing = false;
              onRefreshed(accessToken);
              subscribers = [];
            })
            .catch(() => {
              store.commit("user/clear");
              router.push({
                name: currentRout.name as string,
                params: { unauthorized: "true", ...currentRout.params },
                query: currentRout.query,
              });
            });
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosClient(originalRequest));
          });
        });
      } else {
        store.commit("user/clear");
        router.push({
          name: currentRout.name as string,
          params: { unauthorized: "true", ...currentRout.params },
          query: currentRout.query,
        });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
